import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as topNavStyles from "./topNav.module.css"
import { Navbar, Nav } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import {
  Linkedin,
  Facebook,
  Instagram,
  ChatRightDotsFill,
} from "react-bootstrap-icons"

const TopNav = () => {
  return (
    // <Navbar expand="lg" variant="light" className={topNavStyles.bgColor}>
    <Navbar
      expand="lg"
      // bg="dark"
      // variant="dark"
      className={topNavStyles.bgColor}
    >
      <Navbar.Brand href="/">
        <StaticImage
          placeholder="blurred"
          width={132}
          height={40}
          className="d-inline-block align-top"
          src="../images/logo-white.png"
          alt="Mise en Place"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto parent">
          <Link to="/">
            <div className={topNavStyles.linkText}>Home</div>
          </Link>
          <Link to="/about">
            <div className={topNavStyles.linkText}>ABOUT</div>
          </Link>
          <Link to="/latest-vacancies">
            <div className={topNavStyles.linkText}>VACANCIES</div>
          </Link>
          {/* <Link to="/employers">
            <div className={topNavStyles.linkText}>EMPLOYERS</div>
          </Link> */}
          <a href="https://cms.miseenplace.co.uk/employers">
            <div className={topNavStyles.linkText}>EMPLOYERS</div>
          </a>
          {/* <Link to="/candidates">
            <div className={topNavStyles.linkText}>CANDIDATES</div>
          </Link> */}
          <a href="https://cms.miseenplace.co.uk/candidates">
            <div className={topNavStyles.linkText}>CANDIDATES</div>
          </a>
          <a href="https://cms.miseenplace.co.uk/contact">
            <div className={topNavStyles.linkText}>CONTACT</div>
          </a>

          {/* <Link to="/contact">
            <div className={topNavStyles.linkText}>CONTACT</div>
          </Link> */}
          <Link to="/international-recruitment">
            <div className={topNavStyles.linkText}>INTERNATIONAL</div>
          </Link>
        </Nav>
        <Nav className="d-none d-lg-flex">
          <Nav.Link
            href="https://www.facebook.com/miseenplacerecruitment"
            target="_blank"
          >
            <Facebook color="#ffffff" />
          </Nav.Link>
          <Nav.Link
            href="https://www.linkedin.com/company/miseenplacerecruitment/"
            target="_blank"
          >
            <Linkedin color="#ffffff" />
          </Nav.Link>
          <Nav.Link
            href="https://www.instagram.com/miseenplacerecruitment/"
            target="_blank"
          >
            <Instagram color="#ffffff" />
          </Nav.Link>
          <Nav.Link href="https://blog.miseenplace.co.uk/" target="_blank">
            <ChatRightDotsFill color="#ffffff" />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

TopNav.propTypes = {
  siteTitle: PropTypes.string,
}

TopNav.defaultProps = {
  siteTitle: ``,
}

export default TopNav
