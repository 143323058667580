import * as React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import * as bottomNavStyles from "./bottomNav.module.css"
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const PagesWrapper = () => (
  <StaticQuery
    query={graphql`
      query {
        allMysqlPages {
          nodes {
            page_title
            slug
            mysqlId
          }
        }
      }
    `}
    render={data => {
      const pages = data.allMysqlPages.nodes

      console.log(pages)

      return (
        <>
          {pages.map((page, i) => (
            <>
              <li>{page.slug}</li>
            </>
          ))}
        </>
      )
    }}
  />
)

const BottomNav = () => {
  return (
    <>
      {/* <PagesWrapper /> */}
      <footer className={bottomNavStyles.bgColor}>
        <Container className={bottomNavStyles.footerNav}>
          <Row className="">
            <Col sm className="d-flex justify-content-center">
              <Nav className="flex-column justify-content">
                <Link to="/privacy-policy">
                  <div className={bottomNavStyles.linkText}>
                    (Privacy Policy)
                  </div>
                </Link>
                <Link to="/catering-jobs-in-london">
                  <div className={bottomNavStyles.linkText}>
                    Catering Jobs in London
                  </div>
                </Link>
                <Link to="/catering-jobs-uk">
                  <div className={bottomNavStyles.linkText}>
                    Catering Jobs UK
                  </div>
                </Link>
                <Link to="/catering-jobs-in-london">
                  <div className={bottomNavStyles.linkText}>
                    Catering Management Jobs
                  </div>
                </Link>
                <Link to="/catering-recruitment">
                  <div className={bottomNavStyles.linkText}>
                    Catering Recruitment
                  </div>
                </Link>
                <Link to="/catering-services-international">
                  <div className={bottomNavStyles.linkText}>
                    Catering Services International
                  </div>
                </Link>
                <Link to="/chef-de-partie-jobs">
                  <div className={bottomNavStyles.linkText}>
                    Chef de Partie Jobs
                  </div>
                </Link>
                <Link to="/contract-catering-recruitment">
                  <div className={bottomNavStyles.linkText}>
                    Contract Catering Recruitment
                  </div>
                </Link>
                <Link to="/demi-chef-jobs">
                  <div className={bottomNavStyles.linkText}>Demi Chef Jobs</div>
                </Link>
              </Nav>
            </Col>
            <Col sm className="d-flex justify-content-center">
              <Nav className="flex-column justify-content">
                <Link to="/executive-chef-jobs">
                  <div className={bottomNavStyles.linkText}>
                    Executive Chef Jobs / Chef de Cuisine
                  </div>
                </Link>
                <Link to="/head-chef-jobs">
                  <div className={bottomNavStyles.linkText}>Head Chef Jobs</div>
                </Link>
                <Link to="/hospitality-jobs">
                  <div className={bottomNavStyles.linkText}>
                    Hospitality Jobs
                  </div>
                </Link>
                <Link to="/hotel-catering-jobs">
                  <div className={bottomNavStyles.linkText}>
                    Hotel Catering Jobs
                  </div>
                </Link>
                <Link to="/international-chef-recruitment">
                  <div className={bottomNavStyles.linkText}>
                    International chef recruitment
                  </div>
                </Link>
                <Link to="/london-restaurant-jobs">
                  <div className={bottomNavStyles.linkText}>
                    London Restaurant Jobs
                  </div>
                </Link>
                <Link to="/private-chef">
                  <div className={bottomNavStyles.linkText}>Private Chef</div>
                </Link>
                <Link to="/restaurant-management-jobs">
                  <div className={bottomNavStyles.linkText}>
                    Restaurant Management Jobs
                  </div>
                </Link>
                <Link to="/sous-chef-jobs">
                  <div className={bottomNavStyles.linkText}>Sous Chef Jobs</div>
                </Link>
                <Link to="/video">
                  <div className={bottomNavStyles.linkText}>Video</div>
                </Link>
              </Nav>
            </Col>
            <Col sm className="d-flex justify-content-center">
              <div>
                <StaticImage
                  className={bottomNavStyles.footerImg}
                  placeholder="blurred"
                  src="../images/skills.png"
                  alt="About"
                  style={{ display: "block" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Navbar expand="lg" className={bottomNavStyles.bgColor}>
          <div className="mx-auto parent">
            <div className={bottomNavStyles.footerText}>
              Candidates seen by prior appointment only: email
              <a href="info@miseenplace.co.uk">info@miseenplace.co.uk</a>
              <br />
              Address: 17 Hanover Square, London W1S 1BN Company registered in
              England & Wales No. 3410583 – Tel:{" "}
              <a href="tel:+44 7 430 9811">+44 7 430 9811</a>
            </div>
          </div>
        </Navbar>
        <Navbar expand="lg" className={bottomNavStyles.bgColor}>
          <div className="mx-auto parent">
            <div className={bottomNavStyles.footerText}>
              © {new Date().getFullYear()} Mise en Place
            </div>
          </div>
        </Navbar>
      </footer>
    </>
  )
}

BottomNav.propTypes = {
  siteTitle: PropTypes.string,
}

BottomNav.defaultProps = {
  siteTitle: ``,
}

export default BottomNav
