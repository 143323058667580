import * as React from "react"
import PropTypes from "prop-types"
// import { Link } from "gatsby"
import * as titleBarStyles from "./titlebar.module.css"
import { Navbar, Nav } from "react-bootstrap"

const TitleBar = ({ title }) => {
  return (
    <Navbar expand="lg" className={titleBarStyles.bgColor}>
      <Nav className="mx-auto parent">
        <div className={titleBarStyles.headingText} href="/test">
          {title}
        </div>
      </Nav>
    </Navbar>
  )
}

TitleBar.propTypes = {
  siteTitle: PropTypes.string,
}

TitleBar.defaultProps = {
  siteTitle: ``,
}

export default TitleBar
