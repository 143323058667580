import * as React from "react"
import PropTypes from "prop-types"
import TopNav from "./topNav"
import Hero from "./hero"
import TitleBar from "./titleBar"
import MiddleNav from "./middleNav"

const Header = ({ siteTitle, splashPage, pageTitle, pageSlug, jobPage }) => {
  // console.log(jobPage)

  return (
    <>
      <header>
        <TopNav />
        {jobPage ? null : <Hero splash={splashPage} pageSlug={pageSlug} />}
        <TitleBar title={pageTitle} />
        <MiddleNav splash={splashPage} />
      </header>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
