import * as React from "react"
import PropTypes from "prop-types"
import BottomNav from "./bottomNav"

const Footer = ({ siteTitle }) => {
  return (
    <>
      <BottomNav />
    </>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
