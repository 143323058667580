import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as middleNavStyles from "./middleNav.module.css"
import { Navbar, Nav } from "react-bootstrap"
import { SquareFill } from "react-bootstrap-icons"

const MiddleNav = splashPage => {
  const splash = splashPage.splash

  return (
    <>
      {splash ? (
        <div className={middleNavStyles.bgColor}>
          <Navbar expand="lg" className="justify-content-center">
            <Nav fill className="mx-auto parent">
              {/* <Link to="/quick-cv">
                <div className={middleNavStyles.linkText}>
                  <SquareFill color="#ce5f26" /> QUICK CV
                </div>
              </Link> */}
              <a href="https://cms.miseenplace.co.uk/candidates">
                <div className={middleNavStyles.linkText}>
                  <SquareFill color="#ce5f26" /> QUICK CV
                </div>
              </a>
              {/* <Link to="/employers">
                <div className={middleNavStyles.linkText}>
                  <SquareFill color="#ce5f26" /> EMPLOYERS
                </div>
              </Link> */}
              <a href="https://cms.miseenplace.co.uk/employers">
                <div className={middleNavStyles.linkText}>
                  <SquareFill color="#ce5f26" /> EMPLOYERS
                </div>
              </a>
              <Link to="/vacancies">
                <div className={middleNavStyles.linkText}>
                  <SquareFill color="#ce5f26" /> JOB SEARCH
                </div>
              </Link>
              <Link to="/testimonials-page">
                <div className={middleNavStyles.linkText}>
                  <SquareFill color="#ce5f26" /> TESTIMONIALS
                </div>
              </Link>
            </Nav>
          </Navbar>
        </div>
      ) : null}
    </>
  )
}

MiddleNav.propTypes = {
  siteTitle: PropTypes.string,
}

MiddleNav.defaultProps = {
  siteTitle: ``,
}

export default MiddleNav
