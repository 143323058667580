import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as HeroStyles from "./hero.module.css"
import { Carousel } from "react-bootstrap"

const Hero = ({ splash, pageSlug }) => {
  // console.log("slug: " + pageSlug)

  return (
    <>
      {!splash ? (
        <>
          {pageSlug === "international-recruitment" ? (
            <StaticImage
              className={HeroStyles.headerImg}
              placeholder="blurred"
              src="../images/international.jpg"
              alt="International"
              style={{ display: "block" }}
            />
          ) : (
            <StaticImage
              className={HeroStyles.headerImg}
              placeholder="blurred"
              src="../images/about.jpg"
              alt="About"
              style={{ display: "block" }}
            />
          )}
        </>
      ) : (
        <Carousel fade indicators={false}>
          <Carousel.Item interval={3000}>
            <StaticImage
              className="d-block w-100"
              placeholder="blurred"
              src="../images/slides/main-page-1.jpg"
              alt="About"
              style={{ display: "block" }}
            />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <StaticImage
              className="d-block w-100"
              src="../images/slides/main-page-2.jpg"
              alt="About"
              style={{ display: "block" }}
            />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <StaticImage
              className="d-block w-100"
              src="../images/slides/main-page-3.jpg"
              alt="About"
              style={{ display: "block" }}
            />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <StaticImage
              className="d-block w-100"
              placeholder="blurred"
              src="../images/slides/main-page-4.jpg"
              alt="About"
              style={{ display: "block" }}
            />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <StaticImage
              className="d-block w-100"
              src="../images/slides/main-page-5.jpg"
              alt="About"
              style={{ display: "block" }}
            />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <StaticImage
              className="d-block w-100"
              src="../images/slides/main-page-6.jpg"
              alt="About"
              style={{ display: "block" }}
            />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <StaticImage
              className="d-block w-100"
              placeholder="blurred"
              src="../images/slides/main-page-7.jpg"
              alt="About"
              style={{ display: "block" }}
            />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <StaticImage
              className="d-block w-100"
              src="../images/slides/main-page-8.jpg"
              alt="About"
              style={{ display: "block" }}
            />
          </Carousel.Item>
        </Carousel>
      )}
    </>
  )
}

export default Hero
